import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints, BreakpointState } from '@angular/cdk/layout';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResponsiveService {

  constructor(private readonly breakpointObserver: BreakpointObserver) { }

  /**
   * Method to check if we are no mobile screens (or tablet in portrait mode)
   * Uses Material helper >> https://material.angular.io/cdk/layout/overview   
   */
  monitorIsMobileScreen(): Observable<BreakpointState> {
    return this.breakpointObserver.observe([Breakpoints.Handset, Breakpoints.TabletPortrait]);
  }
}
