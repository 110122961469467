import { Injectable } from '@angular/core';
import { User } from '../../shared/interfaces/user.interface';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  // ------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------
  // Session & User Helpers -------------------------------------------------------------------------

  static saveUserSession(user, token, rememberMe) {
    if (rememberMe) {
      this.saveLocalCurrentUser(user);
      this.saveLocalString('meta-token', token);
    } else {
      this.saveSessionCurrentUser(user);
      this.saveSessionString('meta-token', token);
    }
  }

  static updateUserSession(user) {
    const userLocal = StorageService.loadLocalCurrentUser();
    if (userLocal) {
      StorageService.saveLocalCurrentUser(user);
    } else {
      StorageService.saveSessionCurrentUser(user);
    }
  }

  static removeUserSession() {
    this.removeFromLocal('user');
    this.removeFromLocal('meta-token');
    this.removeFromSession('user');
    this.removeFromSession('meta-token');
  }


  // ------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------
  // Sesion Helpers ---------------------------------------------------------------------------------

  static saveSessionCurrentUser(user) {
    sessionStorage.setItem('user', JSON.stringify(user));
  }

  static loadSessionCurrentUser() {
    try {
      return JSON.parse(sessionStorage.getItem('user')) as User;
    } catch (error) {
      return null;
    }
  }

  static saveSessionString(key, str) {
    sessionStorage.setItem(key, str);
  }

  static loadSessionString(key) {
    try {
      return sessionStorage.getItem(key);
    } catch (error) {
      return null;
    }
  }

  static removeFromSession(key) {
    return sessionStorage.removeItem(key);
  }

  // ------------------------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------------------------
  // Session & Local storage Helpers --------------------------------------------------------------------------------

  static saveLocalCurrentUser(user) {
    localStorage.setItem('user', JSON.stringify(user));
  }

  static loadLocalCurrentUser() {
    try {
      return JSON.parse(localStorage.getItem('user'));
    } catch (error) {
      return null;
    }
  }

  static saveLocalString(key, str) {
    localStorage.setItem(key, str);
  }

  static loadLocalString(key) {
    try {
      return localStorage.getItem(key);
    } catch (error) {
      return null;
    }
  }

  static removeFromLocal(key) {
    return localStorage.removeItem(key);
  }

}
