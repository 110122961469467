import { Injectable } from '@angular/core';
import { FileInput } from 'ngx-material-file-input';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  /**
   * Method to urelize a given string
   * @param string
   */
  public static urelizeString(string): string {
    const a = 'æøåàáäâèéëêìíïîòóöôùúüûñçßÿœæŕśńṕẃǵǹḿǘẍźḧ·/_,:;';
    const b = 'aoaaaaaeeeeiiiioooouuuuncsyoarsnpwgnmuxzh------';
    const p = new RegExp(a.split('').join('|'), 'g');

    return string
      .toString()
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with -
      .replace(p, c => b.charAt(a.indexOf(c))) // Replace special chars
      .replace(/&/g, '-and-') // Replace & with 'and'
      .replace(/[^\w-]+/g, '') // Remove all non-word chars
      .replace(/--+/g, '-') // Replace multiple - with single -
      .replace(/^-+/, '') // Trim - from start of text
      .replace(/-+$/, ''); // Trim - from end of text
  }

  public static createFormData(form: FormGroup) {
    const formData: FormData = new FormData();

    Object.keys(form.controls).forEach(key => {
      const value = form.get(key).value;
      formData.append(key, value instanceof FileInput ? value.files[0] : value);
    });

    return formData;
  }

  /**
   * Method to return month number in two digit string. i.e: 1 > '01' || 10 > '10'
   * @param month
   */
  public static monthAsTwoDigit(month: number): string {
    return ('0' + month).slice(-2);
  }

  /**
   * Method to return year number in two digit string. i.e: 2019 > '19'
   * @param year
   */
  public static yearAsTwoDigit(year: number): string {
    return `${year}`.substr(-2);
  }

  /**
   * Method to return current month as number. i.e 'May' > 5
   * Remember that in JS january is 0 (so we need to add 1)
   */
  public static currentMonthAsNumber(): number {
    return new Date().getMonth() + 1;
  }

  /**
   * Method to return previous month as number. i.e 'May' > 5
   * Remember that in JS january is 0 (so we can keep current value as previous)
   */
  public static previousMonthAsNumber(): number {
    const currentMonth = new Date().getMonth();
    return currentMonth === 0 ? 12 : currentMonth;
  }

  /**
   * Method to return current year as number. i.e '2019
   */
  public static currentYearAsNumber(): number {
    return new Date().getFullYear();
  }

  /**
   * Method to return previous year as number. i.e '2019
   */
  public static previousYearAsNumber(): number {
    return new Date().getFullYear() - 1;
  }

  /**
   * Method to return previous month/year in desired format. i.e: 04/20
   * @param format
   */
  public static previousMonthYearFormatted(format = 'MM/YY') {
    return moment().subtract(1, 'months').startOf('month').format(format);
  }

  /** Method fot truncating text: default 60 */
  public static truncateText(str, maxLength = 60) {
    if (str.length > maxLength) {
      return `${str.substring(0, maxLength)}...`;
    } else {
      return str;
    }
  }
}
