import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { StorageService } from './storage.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {
  /**
   * Generic method to process back error responses to get response message
   * @param response
   */
  static handleError(response: HttpErrorResponse) {
    let error;
    if (response.error instanceof ErrorEvent) {
      error = response.error.message;
    } else {
      if (response.error && response.error.status === 'unauthorized' && response.error.code === 'DENAPP-9') {
        StorageService.removeUserSession();
      }
      error = { error: response.error };
    }

    return throwError(error);
  }
}
