import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  NavigationExtras,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
} from '@angular/router';
import { AppConfig } from '../../configs/app.config';
import { AuthService } from '../services/auth.service';
import { Location } from '@angular/common';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly location: Location
  ) {}

  /**
   * Method to check if user is logged in order to activate a resource. If user is not,
   * redirect to sign-in or sign-up page (depends if the user has been logged previously).
   * Also if user is not logged, add origin URL in base64, so user can be redirect after
   * @param route
   * @param state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.checkUserTokenValidation(state.url);
  }

  /**
   * Method to check if user is logged in order to load a resource. If user is not,
   * redirect to sign-in or sign-up page (depends if the user has been logged previously).
   * Also if user is not logged, add origin URL in base64, so user can be redirect after
   * @param route
   * @param state
   */
  canLoad(route: Route, segments: UrlSegment[]) {
    return this.checkUserTokenValidation(this.location.path());
  }

  private checkUserTokenValidation(url: string) {
    if (this.authService.isLoggedIn()) {
      return true;
    }

    const currentPath = this.location.path().split('?')[0];
    const navigationExtras: NavigationExtras = currentPath === '' ? {} : { queryParams: { origin: btoa(url) } };
    this.router.navigate([AppConfig.routes.auth.login], navigationExtras);
    return false;
  }
}
