import { AuthService } from './../../../../core/services/auth.service';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from '../../../../configs/app.config';
import { User } from '../../../interfaces/user.interface';
import { BreakpointState } from '@angular/cdk/layout';
import { SidebarService } from '../../../../core/services/sidebar.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Sidebar } from 'ng-sidebar';
import { ResponsiveService } from '../../../../core/services/responsive.service';
import { UtilsService } from '../../../../core/services/utils.service';

@Component({
  selector: 'app-sidebar-layout',
  templateUrl: './sidebar-layout.component.html',
  styleUrls: ['./sidebar-layout.component.scss'],
})
export class SidebarLayoutComponent implements OnInit, OnDestroy {
  user: User;
  isAdmin: boolean;
  isAccountManager: boolean;

  isMobile: boolean;
  sidebarOpened = false;
  sidebarMode;
  sidebarDocked;
  dockedSize = '54px';
  closeDockClickOutside = false;

  @ViewChild(Sidebar, { static: false }) sidebar: Sidebar;

  private readonly ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly sidebarService: SidebarService,
    private readonly responsiveService: ResponsiveService
  ) {}

  ngOnInit() {
    this.checkPrivilages();
    this.checkScreenSize();
    this.checkSidebarNotifications();
  }

  /**
   * Method to cleanup subscriptions
   */
  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Method to change sidebar 'opened' status
   */
  toggleSidebar() {
    this.sidebarOpened = !this.sidebarOpened;
  }

  /**
   * Method to access seller-accounts list
   */
  accountsList() {
    this.router.navigate([AppConfig.routes.sellerAccounts.basePath, AppConfig.routes.sellerAccounts.list]);
    this.closeIfActionOnMobile();
  }

  /**
   * Method to access user's list
   */
  usersList() {
    this.router.navigate([AppConfig.routes.users.basePath, AppConfig.routes.users.list]);
    this.closeIfActionOnMobile();
  }

  /**
   * Method to access sellerAccount public page
   * @param sellerAccount
   */
  sellerAccountAccess(sellerAccount) {
    this.router.navigate([
      AppConfig.routes.sellerAccounts.basePath,
      sellerAccount.id,
      UtilsService.urelizeString(sellerAccount.name),
    ]);
  }

  /** Method to remove user from local/session */
  logout() {
    this.authService.logOut();
    this.router.navigate(['/auth/login']);
    this.closeIfActionOnMobile();
  }

  /**
   * Method to check if there is sidebar notification
   */
  private checkSidebarNotifications() {
    this.sidebarService
      .listenSidebarStatusChange()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe({
        next: () => {
          if (this.sidebar.opened) {
            this.sidebar.close();
          } else if (this.sidebar.close) {
            this.sidebar.open();
          }
        },
      });
  }

  /**
   * Method to detect if screen matches screen size & orientation
   */
  private checkScreenSize() {
    this.responsiveService
      .monitorIsMobileScreen()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.sidebarMode = 'over';
          this.sidebarDocked = false;
          this.isMobile = true;
        } else {
          this.sidebarMode = 'push';
          this.sidebarDocked = true;
          this.isMobile = false;
        }
      });
  }

  /**
   * Method to close sidebar if any action is clicked and
   * we are on mobile
   */
  private closeIfActionOnMobile() {
    if (this.isMobile && this.sidebar.opened) {
      this.sidebar.close();
    }
  }

  /**
   * Method to check privilages
   */
  private checkPrivilages() {
    this.user = this.authService.getUser();
    this.isAdmin = this.user.admin;
    this.isAccountManager =
      !this.isAdmin && this.user.roles['account_manager'] && this.user.roles['account_manager'].length > 0;
  }
}
