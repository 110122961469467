import { Component, OnInit, NgZone, OnDestroy } from '@angular/core';
import { SellerAccountService } from '../../core/services/seller-account.service';
import { SharedService } from '../../core/services/shared.service';
import { ChartsService } from '../../shared/services/charts.service';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { AlertService } from '../../core/services/alert.service';
import { takeUntil } from 'rxjs/operators';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_frozen from '@amcharts/amcharts4/themes/frozen';
import { BreakpointState } from '@angular/cdk/layout';
import { ResponsiveService } from '../../core/services/responsive.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  isMobile: boolean;
  totalSellerAccounts: number;
  totalProducts: number;
  totalSales: number;
  totalSalesBySeller: Array<any>;
  totalSalesByMarket: Array<any>;

  chartProductsBySeller: am4charts.PieChart;
  chartSalesBySeller: am4charts.PieChart;
  chartSalesByMarket: am4charts.XYChart;

  private readonly ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private readonly sellerAccountService: SellerAccountService,
    private readonly sharedService: SharedService,
    private readonly chartsService: ChartsService,
    private readonly translateService: TranslateService,
    private readonly alertService: AlertService,
    private readonly responsiveService: ResponsiveService,
    private zone: NgZone
  ) {}

  ngOnInit() {
    this.checkScreenSize();
    this.getOverviewKpis();
  }

  ngOnDestroy() {
    this.zone.runOutsideAngular(() => {
      if (this.chartProductsBySeller) {
        this.chartProductsBySeller.dispose();
      }
      if (this.chartSalesBySeller) {
        this.chartSalesBySeller.dispose();
      }
      if (this.chartSalesByMarket) {
        this.chartSalesByMarket.dispose();
      }
    });

    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Method to detect if screen matches screen size & orientation
   */
  private checkScreenSize() {
    this.responsiveService
      .monitorIsMobileScreen()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
  }

  /**
   * Method to retrieve and set overview kpis
   */
  private getOverviewKpis() {
    this.sellerAccountService
      .overviewKpis()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        response => {
          this.totalSellerAccounts = response.total_seller_accounts;

          this.totalProducts = response.total_products_by_seller.reduce(
            (accumulator, data) => accumulator + data.products,
            0
          );

          this.totalSales = response.total_sales_by_market.length
            ? response.total_sales_by_market.reduce((accumulator, data) => accumulator + data.sales, 0)
            : 0;
          this.totalSalesBySeller = response.total_sales_by_seller;
          this.totalSalesByMarket = response.total_sales_by_market;

          this.generateProductsBySeller(response.total_products_by_seller);

          if (this.totalSalesBySeller.length) {
            this.generateSalesBySeller(this.totalSalesBySeller);
          }

          if (this.totalSalesByMarket.length) {
            this.generateSalesByMarket(this.totalSalesByMarket);
          }
        },
        error => {
          this.checkError(error);
        }
      );
  }

  /**
   * Method to generate PieChart with products by seller
   * @param total_products_by_seller
   */
  private generateProductsBySeller(total_products_by_seller: Array<any>) {
    am4core.unuseAllThemes();
    const chart = am4core.create('chartProductsBySeller', am4charts.PieChart);

    this.chartsService.generatePieChart(chart, 'products', 'seller_account_name', false, true, 'right', true);

    // set chart data to start rendering
    chart.data = total_products_by_seller;

    this.chartProductsBySeller = chart;
  }

  /**
   * Method to generate PieChart with sales by seller
   * @param total_products_by_seller
   */
  private generateSalesBySeller(total_sales_by_seller: Array<any>) {
    am4core.useTheme(am4themes_frozen);

    const chart = am4core.create('chartSalesBySeller', am4charts.PieChart);
    this.chartsService.generatePieChart(chart, 'sales', 'seller_account_name', false, true, 'right');

    // set chart data to start rendering
    chart.data = total_sales_by_seller;

    this.chartSalesBySeller = chart;
  }

  /**
   * Method to generate ColumnChart with sales by market
   * @param total_products_by_seller
   */
  private generateSalesByMarket(total_sales_by_market: Array<any>) {
    am4core.unuseAllThemes();

    const chart = am4core.create('chartSalesByMarket', am4charts.XYChart);
    const salesTitle = this.translateService.instant('dashboard.kpi.sales-market-axe');
    const marketplacesByName = total_sales_by_market.reduce(
      (obj, data) => ({ ...obj, [data.marketplace_name]: { country_code: data.country } }),
      {}
    );
    const flagImageForColumn = this.chartsService.generateFlagsBulletForColumnChart(marketplacesByName);

    // create all axis
    const categoryAxis = this.chartsService.generateXAxe(chart, 'marketplace_name', this.isMobile);
    categoryAxis.dataItems.template.bullet = flagImageForColumn;

    const valueAxisSales = this.chartsService.generateYAxe(
      chart,
      salesTitle,
      { fontWeight: 'bolder', marginRight: 12 },
      this.isMobile,
      false,
      false
    );

    // create column chart
    this.chartsService.generateColumnSeries(chart, valueAxisSales, salesTitle, 'sales', 'marketplace_name', true, true);

    // set chart data to start rendering
    chart.data = total_sales_by_market;

    this.chartSalesByMarket = chart;
  }

  /**
   * Display error
   * @param error
   */
  private checkError(error?: any) {
    this.sharedService.hideSpinner();
    this.alertService.error(error);
  }
}
