import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { Router } from '@angular/router';
import { AppConfig } from '../../configs/app.config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

const defaultCloseTimeout = 5000;

const backErrorMessages = {
  generic: _('error.generic'),
  undefined: _('error.undefined'),
  'DENAPP-1': _('error.back.DENAPP-1'),
  'DENAPP-2': _('error.back.DENAPP-2'),
  'DENAPP-3': _('error.back.DENAPP-3'),
  'DENAPP-4': _('error.back.DENAPP-4'),
  'DENAPP-5': _('error.back.DENAPP-5'),
  'DENAPP-6': _('error.back.DENAPP-6'),
  'DENAPP-7': _('error.back.DENAPP-7'),
  'DENAPP-8': _('error.back.DENAPP-8'),
  'DENAPP-9': _('error.back.DENAPP-9')
};

const backErrorActions = {
  TOKEN_EXPIRED: _('error.action.DENAPP-9')
};

@Injectable({
  providedIn: 'root'
})
export class AlertService {
  private readonly ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private snackBar: MatSnackBar
  ) {}

  success(message: string | string[] | Object, action?: string, _duration?: number) {
    return this.alert(message, 'success-snack', action, _duration);
  }

  error(message: string | string[] | Object, action?: string, _duration?: number) {
    return this.alert(message, 'alert-snack', action, _duration);
  }

  private alert(
    message: string | string[] | Object,
    styleClass: string,
    action?: string,
    _duration?: number
  ): MatSnackBarRef<SimpleSnackBar> {
    let translatedMessage: string;

    if (message['error'] && message['error']['code']) {
      translatedMessage = this.translateService.instant(backErrorMessages[message['error']['code']]);
    } else if (message['message']) {
      translatedMessage = this.translateService.instant(message['message'].toString());
    } else if (typeof message === 'string') {
      translatedMessage = this.translateService.instant(message.toString());
    } else {
      translatedMessage = this.translateService.instant(_('error.back.common-1'));
    }

    const duration = _duration ? _duration : defaultCloseTimeout;

    // if no action but token expiration error detected, add custom action to refresh screen
    if (!action && message['error'] && message['error']['code'] === 'DENAPP-9') {
      action = this.translateService.instant(backErrorActions.TOKEN_EXPIRED);
    }

    const snackBarRef = this.snackBar.open(translatedMessage, action, {
      duration: duration,
      panelClass: styleClass,
      horizontalPosition: 'right',
      verticalPosition: 'top'
    });

    snackBarRef
      .onAction()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
        this.router.navigate([AppConfig.routes.auth.login]);
      });

    return snackBarRef;
  }
}
