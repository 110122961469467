import { Injectable } from '@angular/core';
import { Subject, Observable, interval, animationFrameScheduler, timer } from 'rxjs';
import { takeUntil, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class SidebarService {

  private readonly sidebarStatus = new Subject<void>();

  constructor() {}

  /**
   * Method to check for changes on sidebar status
   */
  listenSidebarStatusChange(): Observable<void> {
    return this.sidebarStatus.asObservable();
  }

  /**
   * Method to change sidebar current status (if close > open / if open > close)
   */
  toggleStatus(): void {
    this.sidebarStatus.next();
  }

}
