<ng-sidebar-container>
  <!-- sidebar menu -->
  <ng-sidebar
    #sidebar
    [mode]="sidebarMode"
    [dock]="sidebarDocked"
    [dockedSize]="dockedSize"
    [closeOnClickOutside]="closeDockClickOutside"
    [sidebarClass]="'dendary-sidebar'">
    <div class="sidebar-menu">
      <!-- --------------------------------------------------------------------- -->
      <!-- MENU SECTION FOR SELLER-ACCOUNTS-->
      <!-- --------------------------------------------------------------------- -->
      <div class="sidebar-menu-section"  *ngIf="isAdmin || isAccountManager">
        <!-- Visible sidebar-icon when collapsed -->
        <mat-icon
          [class.sidebar-closed]="!sidebar.opened"
          *ngIf="!sidebar.opened"
          aria-hidden="false"
          aria-label="SellerAccounts"
          (click)="isAdmin ? accountsList() : sidebar.open()">
          shop
        </mat-icon>

        <!-- Menu to access seller-accounts info -->
        <mat-expansion-panel *ngIf="sidebar.opened" class="mat-elevation-z0" [expanded]="true">
          <!-- Section Header -->
          <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
            <mat-panel-title>
              <mat-icon aria-hidden="false" aria-label="SellerAccounts">shop</mat-icon>
              <span>Seller Accounts</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <!-- Section List For Admin -->
          <mat-nav-list *ngIf="isAdmin">
            <a mat-list-item (click)="accountsList()">{{ 'sidebar-menu.seller-account-list' | translate }}</a>
          </mat-nav-list>

          <!-- Section List For Account Managers -->
          <ng-container *ngIf="isAccountManager">
            <mat-nav-list>
              <a mat-list-item
                *ngFor="let sellerAccount of user.roles['account_manager']"
                (click)="sellerAccountAccess(sellerAccount)">
                {{ sellerAccount.name }}
              </a>
            </mat-nav-list>
          </ng-container>
        </mat-expansion-panel>
      </div>

      <!-- --------------------------------------------------------------------- -->
      <!-- MENU SECTION FOR USERS -->
      <!-- --------------------------------------------------------------------- -->
      <div class="sidebar-menu-section" *ngIf="isAdmin">
        <!-- Visible sidebar-icon when collapsed -->
        <mat-icon
          [class.sidebar-closed]="!sidebar.opened"
          *ngIf="!sidebar.opened"
          aria-hidden="false"
          aria-label="Users"
          (click)="sidebar.open()">
          people_alt
        </mat-icon>

        <mat-expansion-panel *ngIf="sidebar.opened" class="mat-elevation-z0" [expanded]="true">
          <!-- Section Header -->
          <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
            <mat-panel-title>
              <mat-icon aria-hidden="false" aria-label="Settings">people_alt</mat-icon>
              <span>{{ 'sidebar-menu.users' | translate }}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <!-- Section List -->
          <mat-nav-list>
            <a mat-list-item (click)="usersList()">{{ 'sidebar-menu.users-list' | translate }}</a>
          </mat-nav-list>
        </mat-expansion-panel>
      </div>

      <!-- --------------------------------------------------------------------- -->
      <!-- MENU SECTION FOR SETTINGS -->
      <!-- --------------------------------------------------------------------- -->
      <div class="sidebar-menu-section">
        <!-- Visible sidebar-icon when collapsed -->
        <mat-icon
          [class.sidebar-closed]="!sidebar.opened"
          *ngIf="!sidebar.opened"
          aria-hidden="false"
          aria-label="Settings"
          (click)="sidebar.open()">
          settings
        </mat-icon>

        <mat-expansion-panel *ngIf="sidebar.opened" class="mat-elevation-z0" [expanded]="true">
          <!-- Section Header -->
          <mat-expansion-panel-header [collapsedHeight]="'48px'" [expandedHeight]="'48px'">
            <mat-panel-title>
              <mat-icon aria-hidden="false" aria-label="Settings">settings</mat-icon>
              <span>{{ user.name }}</span>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <!-- Section List -->
          <mat-nav-list>
            <a mat-list-item (click)="logout()">{{ 'sidebar-menu.logout' | translate }}</a>
          </mat-nav-list>
        </mat-expansion-panel>
      </div>
    </div>

    <div *ngIf="!isMobile" class="sidebar-footer" [class.opened]="sidebar.opened">
      <mat-divider></mat-divider>
      <mat-icon *ngIf="!sidebar.opened" aria-hidden="false" aria-label="Show Sidebar" (click)="sidebar.open()">
        arrow_forward_ios
      </mat-icon>
      <mat-icon *ngIf="sidebar.opened" aria-hidden="false" aria-label="Hide Sidebar" (click)="sidebar.close()">
        arrow_back_ios
      </mat-icon>
    </div>
  </ng-sidebar>

  <!-- sidebar content -->
  <div ng-sidebar-content>
    <router-outlet></router-outlet>
  </div>
</ng-sidebar-container>
