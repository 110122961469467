<mat-toolbar class="navbar">
  <div class="navbar-logo">
    <a [routerLink]="logged && (isAdmin || isAccountManager) ? '/dashboard' : '/'">
      <span>Dendary</span>
      App
    </a>
  </div>
  <div class="navbar-menu">
    <!-- MENU WHEN USER IS NOT LOGGED -->
    <ng-container *ngIf="!logged">
      <div id="language">
        <button mat-button [matMenuTriggerFor]="menuLanguage">
          <span>{{ 'header.lang.title' | translate }}</span>
          <mat-icon aria-hidden="false" aria-label="Language">keyboard_arrow_down</mat-icon>
        </button>

        <mat-menu #menuLanguage="matMenu">
          <button mat-menu-item (click)="changeLanguage('es')">{{ 'header.lang.es' | translate }}</button>
          <button mat-menu-item (click)="changeLanguage('en')">{{ 'header.lang.en' | translate }}</button>
        </mat-menu>
      </div>
    </ng-container>

    <!-- MENU WHEN USER IS LOGGED -->
    <ng-container *ngIf="logged">
      <div *ngIf="isMobile" id="menu-options">
        <button mat-icon-button>
          <mat-icon aria-hidden="false" aria-label="Menu-Options" (click)="toggleSidebar()">menu</mat-icon>
        </button>
      </div>

      <div *ngIf="!isMobile" class="header__logout">
        <button mat-icon-button>
          <mat-icon aria-hidden="false" aria-label="Logout" (click)="logout()">power_settings_new</mat-icon>
        </button>
      </div>
    </ng-container>
  </div>
</mat-toolbar>