import { Component, OnInit, OnDestroy, ChangeDetectorRef, ApplicationRef, Inject } from '@angular/core';
import { Location } from '@angular/common';
import { Subject, interval, concat } from 'rxjs';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AuthService } from './core/services/auth.service';
import { AppConfig } from './configs/app.config';
import { StorageService } from './core/services/storage.service';
import { SharedService } from './core/services/shared.service';

declare const require;
import * as moment from 'moment';
import { takeUntil, first } from 'rxjs/operators';
import { SwUpdate } from '@angular/service-worker';
import { WINDOW } from './core/services/window.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  logged: boolean;
  showLoading = false;

  private readonly ngUnsubscribe: Subject<void> = new Subject();

  constructor(
    private readonly router: Router,
    private readonly translateService: TranslateService,
    private readonly authService: AuthService,
    private readonly sharedService: SharedService,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly location: Location,
    private readonly appRef: ApplicationRef,
    private readonly swUpdate: SwUpdate,
    @Inject(WINDOW) private readonly window: Window
  ) {
    this.logged = this.authService.isLoggedIn();
  }

  ngOnInit(): void {
    this.checkForUpdates();
    this.initializeLanguages();
    this.subscribeForRouterEvents();
    this.subscribeToUserLogin();
    this.subscribeToSpinnerAction();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  /**
   * Method that uses serviceWorker features to check for App Updates
   */
  private checkForUpdates() {
    if (this.swUpdate.isEnabled) {
      this.swUpdate.checkForUpdate().then(() => {
        // Allow the app to stabilize first, before starting polling for updates with `interval()`.
        const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
        const everyFiveMinutes$ = interval(1 * 60 * 1000);
        const everyFiveMinutesOnceAppIsStable$ = concat(appIsStable$, everyFiveMinutes$);
        everyFiveMinutesOnceAppIsStable$.subscribe(() => this.swUpdate.checkForUpdate());
      });

      // if updates are available, force update and window reload
      this.swUpdate.available.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
        // clears cache
        if ('caches' in this.window) {
          caches.keys().then(keyList => Promise.all(keyList.map(key => caches.delete(key))));
        }

        // updates app version
        this.swUpdate.activateUpdate().then(() => this.window.location.reload());
      });
    }
  }

  /**
   * Sets all translation settings
   */
  private initializeLanguages() {
    // add supported languages
    this.translateService.addLangs(AppConfig.language.availableLanguages);

    // set default language
    const language = AppConfig.language.defaultLanguage;
    this.translateService.setDefaultLang(language);
    this.translateService.setTranslation(language, require(`../assets/i18n/${language}.json`));

    // check if user lang is available, if not guess lang
    const userLang = this.authService.userLang();
    const localLang = StorageService.loadLocalString('lang');
    let browserLang = this.translateService.getBrowserLang();
    if (userLang) {
      this.translateService.use(userLang);
      moment.locale(userLang);
    } else if (localLang) {
      this.translateService.use(localLang);
      moment.locale(localLang);
    } else {
      browserLang = AppConfig.language.availableLanguages.includes(browserLang)
        ? browserLang
        : this.translateService.getDefaultLang();
      this.translateService.use(browserLang);
      StorageService.saveLocalString('lang', browserLang);
      moment.locale(browserLang);
    }

    // subscribe to lang changes
    this.subscribeForLanguageChange();
  }

  /**
   * Controls navigation events to redirect user if needed.
   * If user is not logged-in and tries to naviagte, re-direct to sign-in page
   */
  private subscribeForRouterEvents() {
    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe(event => {
      if (event instanceof NavigationStart) {
        this.sharedService.showSpinner();
      } else if (event instanceof NavigationEnd) {
        this.sharedService.hideSpinner();
        this.logged = this.authService.isLoggedIn();
        const currentPath = this.location.path().split('?')[0];

        if (currentPath !== AppConfig.routes.auth.login && !this.logged) {
          this.router.navigate([AppConfig.routes.auth.login]);
        } else if (currentPath === AppConfig.routes.auth.login && this.logged) {
          this.authService.userRedirect();
        } else if (currentPath === '' && this.logged) {
          this.authService.userRedirect();
        }
      }
    });
  }

  /**
   * Controls if language change is requested
   */
  private subscribeForLanguageChange() {
    this.sharedService.translateSubject.subscribe((lang: string) => {
      this.translateService.use(lang);
      StorageService.saveLocalString('lang', lang);
      moment.locale(lang);
    });
  }

  /**
   * Use to detect when user logs-in to launch processes
   */
  private subscribeToUserLogin() {
    this.authService.onLogin.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.logged = true;
    });
  }

  /**
   * Method for listening for spinner event
   */
  private subscribeToSpinnerAction() {
    this.sharedService
      .checkSpinner()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((loading: boolean) => {
        this.showLoading = loading;
        this.changeDetector.detectChanges();
      });
  }
}
