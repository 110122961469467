import { SidebarService } from './../../../../core/services/sidebar.service';
import { SharedService } from './../../../../core/services/shared.service';
import { Component, OnInit } from '@angular/core';
import { Router, RouterEvent, NavigationEnd } from '@angular/router';
import { AuthService } from '../../../../core/services/auth.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BreakpointState } from '@angular/cdk/layout';
import { ResponsiveService } from '../../../../core/services/responsive.service';
import { User } from '../../../interfaces/user.interface';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  user: User;
  isAdmin: boolean;
  isAccountManager: boolean;

  /** Inidicates if user is logged */
  logged: boolean;
  isMobile: boolean;

  /** Subject to unsubscribe */
  private readonly ngUnsubscribe: Subject<any> = new Subject();

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly sharedService: SharedService,
    private readonly sidebarService: SidebarService,
    private readonly responsiveService: ResponsiveService
  ) {
    this.subscribeToRouterChange();
  }

  ngOnInit() {
    this.checkScreenSize();
    this.checkPrivilages();
  }

  /**
   * Method to request language change (AppComponent is listening to translateSubject to
   * perform the request)ss
   * @param lang
   */
  changeLanguage(lang) {
    this.sharedService.translateSubject.next(lang);
  }

  /** Method to remove user from local/session */
  logout() {
    this.authService.logOut();
    this.router.navigate(['/auth/login']);
  }

  /**
   * Method to toggle (open) sidebar
   */
  toggleSidebar() {
    this.sidebarService.toggleStatus();
  }

  /**
   * Use to check, every route change, if user is logged
   */
  private subscribeToRouterChange() {
    this.router.events.pipe(takeUntil(this.ngUnsubscribe)).subscribe((event: RouterEvent) => {
      if (event instanceof NavigationEnd) {
        this.resetParams();
      }
    });
  }

  /**
   * Method to check if user is still connected
   */
  private resetParams() {
    this.logged = this.authService.isLoggedIn();
  }

  /**
   * Method to detect if screen matches screen size & orientation
   */
  private checkScreenSize() {
    this.responsiveService
      .monitorIsMobileScreen()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.isMobile = true;
        } else {
          this.isMobile = false;
        }
      });
  }

  /**
   * Method to check privilages
   */
  private checkPrivilages() {
    this.user = this.authService.getUser();
    this.isAdmin = this.user && this.user.admin;
    this.isAccountManager =
      this.user && !this.isAdmin && this.user.roles['account_manager'] && this.user.roles['account_manager'].length > 0;
  }
}
