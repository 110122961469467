import { AuthService } from './../services/auth.service';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route, UrlSegment } from '@angular/router';
import { AlertService } from '../services/alert.service';
import { _ } from '@biesbjerg/ngx-translate-extract/dist/utils/utils';
import { AppConfig } from '../../configs/app.config';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly alertService: AlertService,
    private readonly authService: AuthService
  ) {}

  /**
   * Method used to decide if user has privileges to activate route or not
   * @param route
   * @param state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const fullPath = state.url;
    return this.manageRolePrivileges(route, fullPath);
  }

  /**
   * Method used to decide if user has privileges to load module or not
   * @param route
   * @param state
   */
  canLoad(route: Route, segments: UrlSegment[]) {
    return this.manageRolePrivileges(route);
  }

  /**
   * Method to manage role privileges used to activate a route or load a module
   */
  private manageRolePrivileges(route: ActivatedRouteSnapshot | Route, fullPath?: string) {
    const allowedRoles = route.data['roles'];
    const user = this.authService.getUser();

    // verify admin privileges
    if (allowedRoles.includes(AppConfig.roles.admin) && user.admin) {
      return true;
    }

    // verify accountManager privileges
    if (
      allowedRoles.includes(AppConfig.roles.accountManager) &&
      user.roles &&
      user.roles[AppConfig.roles.accountManager]
    ) {
      return true;
    }

    // verify accountManager privileges
    if (allowedRoles.includes(AppConfig.roles.seller) && user.roles && user.roles[AppConfig.roles.seller]) {
      return true;
    }

    this.alertService.error(_('error.back.DENAPP-1'));

    this.authService.userRedirect();
    return false;
  }
}
