<article>
  <section>
    <div class="container">
      <div id="dashboard-header" class="row">
        <div class="col-12 text-center align-self-center">
          <h1 class="mat-display-1">Dendary App Dashboard</h1>
        </div>
      </div>
    </div>
  </section>

  <!-- Overview kpi numbers -->
  <section>
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-4 dashboard__main-kpi-card">
          <mat-card>
            <mat-card-header>
              <mat-card-title class="title-chart">Seller Accounts</mat-card-title>
              <mat-card-subtitle>{{ 'dashboard.kpi.sellers-legend' | translate }}</mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <p class="dashboard__main-kpi">
                <app-spinner *ngIf="!totalSellerAccounts" [loading]="true" [fullScreen]="false"></app-spinner>
                <span class="main-kpi-blue" *ngIf="totalSellerAccounts">{{ totalSellerAccounts }}</span>
              </p>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-12 col-lg-4 dashboard__main-kpi-card">
          <mat-card>
            <mat-card-header>
              <mat-card-title class="title-chart">
                {{ 'dashboard.kpi.products-title' | translate }}
              </mat-card-title>
              <mat-card-subtitle>
                {{ 'dashboard.kpi.products-legend' | translate }}
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <p class="dashboard__main-kpi">
                <app-spinner *ngIf="!totalProducts" [loading]="true" [fullScreen]="false"></app-spinner>
                <span class="main-kpi-green" *ngIf="totalProducts">{{ totalProducts }}</span>
              </p>
            </mat-card-content>
          </mat-card>
        </div>
        <div class="col-12 col-lg-4 dashboard__main-kpi-card">
          <mat-card>
            <mat-card-header>
              <mat-card-title class="title-chart">
                {{ 'dashboard.kpi.sales-title' | translate }}
              </mat-card-title>
              <mat-card-subtitle>
                {{ 'dashboard.kpi.sales-legend' | translate }}
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <p class="dashboard__main-kpi">
                <app-spinner *ngIf="totalSales === undefined" [loading]="true" [fullScreen]="false"></app-spinner>
                <span class="main-kpi-pink" *ngIf="totalSales !== undefined">{{ totalSales | number: '1.2-2' }} €</span>
              </p>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </section>

  <section class="dashboard_section-top-margin">
    <div class="container">
      <div class="row">
        <div class="col-12 col-lg-6">
          <mat-card class="dashboard__main-kpi-card">
            <mat-card-header>
              <mat-card-title class="title-chart">
                {{ 'dashboard.kpi.products-seller-title' | translate }}
              </mat-card-title>
              <mat-card-subtitle>
                {{ 'dashboard.kpi.products-seller-legend' | translate }}
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <div id="chartProductsBySeller" style="width: 100%; height: 350px;">
                <app-spinner [loading]="true" [fullScreen]="false"></app-spinner>
              </div>
            </mat-card-content>
          </mat-card>
        </div>

        <div class="col-12 col-lg-6">
          <mat-card class="dashboard__main-kpi-card">
            <mat-card-header>
              <mat-card-title class="title-chart">
                {{ 'dashboard.kpi.sales-seller-title' | translate }}
              </mat-card-title>
              <mat-card-subtitle>
                {{ 'dashboard.kpi.sales-seller-legend' | translate }}
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <div id="chartSalesBySeller" style="width: 100%; height: 350px;">
                <app-spinner *ngIf="totalSalesBySeller === undefined" [loading]="true" [fullScreen]="false"></app-spinner>
                <h3 class="dashboard__no-data" *ngIf="totalSalesBySeller.length === 0">{{ 'dashboard.kpi.no-data' | translate }}</h3>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </section>

  <section class="dashboard_section-top-margin mb-48">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <mat-card>
            <mat-card-header>
              <mat-card-title class="title-chart">
                {{ 'dashboard.kpi.sales-market-title' | translate }}
              </mat-card-title>
              <mat-card-subtitle>
                {{ 'dashboard.kpi.sales-market-legend' | translate }}
              </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
              <div id="chartSalesByMarket" style="width: 100%; height: 350px;">
                <app-spinner *ngIf="totalSalesByMarket === undefined" [loading]="true" [fullScreen]="false"></app-spinner>
                <h3 class="dashboard__no-data" *ngIf="totalSalesByMarket.length === 0">{{ 'dashboard.kpi.no-data' | translate }}</h3>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
    </div>
  </section>
</article>
