import { NgModule, Injectable, ErrorHandler } from '@angular/core';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { WINDOW_PROVIDERS } from './core/services/window.service';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient } from '@angular/common/http';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { errorHandlerFactory } from './app.error-handler';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  imports: [
    CoreModule,
    SharedModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      loader: { provide: TranslateLoader, useFactory: HttpLoaderFactory, deps: [HttpClient] },
    }),
  ],
  declarations: [AppComponent, DashboardComponent],
  providers: [{ provide: ErrorHandler, useFactory: errorHandlerFactory }, WINDOW_PROVIDERS],
  bootstrap: [AppComponent],
})
export class AppModule {}
