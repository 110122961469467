import { Injectable, ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../environments/environment';
import { version } from '../../package.json';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  private readonly displayUserFeedback: boolean;

  constructor(userFeedback = false) {
    Sentry.init({
      dsn: 'https://bee50aa8b34744729f478493cd7dcb3f@sentry.io/2709162',
      environment: environment.env,
      release: 'dendary-app-web@' + version,
      ignoreErrors: ['Non-Error exception captured'],
    });

    this.displayUserFeedback = userFeedback;
  }

  handleError(error) {
    if (this.displayUserFeedback) {
      const eventId = Sentry.captureException(error.originalError || error.error || error);
      Sentry.showReportDialog({ eventId });
    } else {
      Sentry.captureException(error.originalError || error.error || error);
    }
  }
}

export function errorHandlerFactory(): ErrorHandler {
  if (environment.env === 'pro') {
    return new SentryErrorHandler();
  }
  return new ErrorHandler();
}
