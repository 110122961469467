<!-- Header -->
<div class="header-wrapper mat-elevation-z2">
  <app-header></app-header>
</div>

<!-- Body -->
<div class="page-wrapper">
  <ng-container *ngIf="logged; then authenticatedLayout; else notAuthenticatedLayout"></ng-container>
</div>

<ng-template #authenticatedLayout>
  <app-sidebar-layout></app-sidebar-layout>
</ng-template>

<ng-template #notAuthenticatedLayout>
  <router-outlet></router-outlet>
</ng-template>

<app-spinner [loading]="showLoading"></app-spinner>
