import { RouterModule } from '@angular/router';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SidebarModule } from 'ng-sidebar';
import { MaterialFileInputModule } from 'ngx-material-file-input';

/** Material modules being used */
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDividerModule } from '@angular/material/divider';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatTableModule } from '@angular/material/table';
import { MatRippleModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTooltipModule } from '@angular/material/tooltip';

/** Shared components */
import { ControlMessagesComponent } from './components/forms/control-messages/control-messages.component';
import { HeaderComponent } from './components/layouts/header/header.component';
import { AvatarComponent } from './components/utils/avatar/avatar.component';
import { SidebarLayoutComponent } from './components/layouts/sidebar-layout/sidebar-layout.component';
import { DeleteSellerAccountComponent } from './components/dialogs/delete-seller-account/delete-seller-account.component';
import { SpinnerComponent } from './components/layouts/spinner/spinner.component';

/** Shared pipes */
import { TruncatePipe } from './pipes/truncate.pipe';
import { AmazonLinkPipe } from './pipes/amazon-link.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule.forChild(),
    SidebarModule.forRoot(),
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatMenuModule,
    MatDividerModule,
    MatSelectModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatListModule,
    MatTableModule,
    MatRippleModule,
    MaterialFileInputModule,
    MatDialogModule,
    MatTooltipModule,
  ],
  declarations: [
    ControlMessagesComponent,
    HeaderComponent,
    AvatarComponent,
    SidebarLayoutComponent,
    TruncatePipe,
    AmazonLinkPipe,
    DeleteSellerAccountComponent,
    SpinnerComponent,
  ],
  exports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    MatCardModule,
    MatButtonModule,
    MatFormFieldModule,
    MatInputModule,
    MatIconModule,
    MatCheckboxModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatMenuModule,
    MatDividerModule,
    MatSelectModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatPaginatorModule,
    MatExpansionModule,
    MatListModule,
    MatTableModule,
    MatRippleModule,
    MaterialFileInputModule,
    MatDialogModule,
    MatTooltipModule,
    ControlMessagesComponent,
    HeaderComponent,
    SidebarLayoutComponent,
    AvatarComponent,
    TruncatePipe,
    AmazonLinkPipe,
    DeleteSellerAccountComponent,
    SpinnerComponent,
  ],
  providers: [],
  schemas: [NO_ERRORS_SCHEMA],
})
export class SharedModule {}
