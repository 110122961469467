import { InjectionToken } from '@angular/core';

export let APP_CONFIG = new InjectionToken('app.config');

const authBasePath = 'auth';
const sellerAccountBasePath = 'seller-accounts';
const usersBasePath = 'users';

export const AppConfig = {
  language: {
    defaultLanguage: 'es',
    availableLanguages: ['es', 'en'],
  },

  amazonMarkets: {
    A1RKKUPIHCS9HS: {
      name: 'Amazon.es',
      url: 'https://www.amazon.es/',
    },
    A1F83G8C2ARO7P: {
      name: 'Amazon.co.uk',
      url: 'https://www.amazon.co.uk/',
    },
    APJ6JRA9NG5V4: {
      name: 'Amazon.it',
      url: 'https://www.amazon.it/',
    },
    A13V1IB3VIYZZH: {
      name: 'Amazon.fr',
      url: 'https://www.amazon.fr/',
    },
    A1PA6795UKMFR9: {
      name: 'Amazon.de',
      url: 'https://www.amazon.de/',
    },
    A1805IZSGTT6HS: {
      name: 'Amazon.nl',
      url: 'https://www.amazon.nl/',
    },
    ATVPDKIKX0DER: {
      name: 'Amazon.com',
      url: 'https://www.amazon.com/',
    },
    A2EUQ1WTGCTBG2: {
      name: 'Amazon.ca',
      url: 'https://www.amazon.ca/',
    },
    A1AM78C64UM0Y8: {
      name: 'Amazom.com.mx',
      url: 'https://www.amazon.com.mx/',
    },
  },

  roles: {
    admin: 'admin',
    accountManager: 'account_manager',
    seller: 'seller'
  },

  routes: {
    main: {
      dashboard: 'dashboard',
    },

    auth: {
      basePath: authBasePath,
      login: `/${authBasePath}/login`,
    },

    sellerAccounts: {
      basePath: sellerAccountBasePath,
      new: 'new',
      dashboard: 'dashboard',
      list: 'list',
      detail: ':sellerAccountId/:name',
      edit: 'edit/:sellerAccountId/:name',
    },

    users: {
      basePath: usersBasePath,
      list: 'list',
      detail: ':userId/:name',
      new: 'new',
    }
  },
};
