import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {
  @Input() loading: boolean;
  @Input() fullScreen: boolean;

  constructor() {
    this.loading = false;
    this.fullScreen = true;
  }
}
