import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';

am4core.useTheme(am4themes_animated);

export interface YAxeStyles {
  fontWeight?: am4core.FontWeight;
  marginLeft?: number;
  marginRight?: number;
}

@Injectable({
  providedIn: 'root',
})
export class ChartsService {
  tooltipText =
    '[#fff font-size: 15px]{name} ' +
    this.translateService.instant('products-dashboard.axes.in') +
    ' {categoryX}:\n[/][#fff font-size: 20px]{valueY}[/] [#fff]{additional}[/]';

  constructor(private readonly translateService: TranslateService) {}

  /**
   * Method to add chart exporting capabilities
   * @param chart
   */
  addChartExportCapabilities(chart: am4charts.XYChart) {
    chart.exporting.menu = new am4core.ExportMenu();
  }

  /**
   * Method to generate the X-axe (category related axe: i.e > months)
   * @param categoryField: name of the data field to be used for x-axe category
   */
  generateXAxe(chart: am4charts.XYChart, categoryField: string, hideAxeLabels = false) {
    const categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());

    // field to be used for X-axes category (generates X-labels)
    categoryAxis.dataFields.category = categoryField;

    // activate/desactivate X-axe grid lines
    categoryAxis.renderer.grid.template.disabled = false;

    // min distance of grid lines
    categoryAxis.renderer.minGridDistance = 30;

    // disable labels (remove text, specially for mobile charts)
    categoryAxis.renderer.labels.template.disabled = hideAxeLabels;

    return categoryAxis;
  }

  /**
   * Method to generate Y-axe (value related axe: i.e > sales)
   * @param chart
   * @param categoryField
   */
  generateYAxe(
    chart: am4charts.XYChart,
    axeTitle: string,
    titleStyles: YAxeStyles,
    hideAxeLabels: boolean,
    hideAxeGrid: boolean,
    renderRight: boolean
  ) {
    const valuesAxis = chart.yAxes.push(new am4charts.ValueAxis());

    // add title to Y-axe
    valuesAxis.title.text = hideAxeLabels ? null : axeTitle;

    // set title style
    this.setYAxeTitleStyles(valuesAxis, titleStyles);

    // hide/show valueAxis labels
    valuesAxis.renderer.labels.template.disabled = hideAxeLabels;

    // hide/show Y-axe grid
    valuesAxis.renderer.grid.template.disabled = hideAxeGrid;

    // render axe in opposite place (right instead of left)
    valuesAxis.renderer.opposite = renderRight;

    return valuesAxis;
  }

  /**
   * Method to generate a column chart
   * @param chart
   * @param valueAxes (the y-axe previously generated)
   * @param serieTitle
   * @param valueField (name of the data field to be used for getting the value)
   * @param categoryField (name of the data field to be used for x-axe category > so value can be matched with category)
   */
  generateColumnSeries(
    chart: am4charts.XYChart,
    valueAxes: am4charts.ValueAxis,
    serieTitle: string,
    valueField: string,
    categoryField: string,
    cursorPointer: boolean,
    changeColumnColors: boolean
  ) {
    const columnSeries = chart.series.push(new am4charts.ColumnSeries());
    columnSeries.name = serieTitle;
    columnSeries.dataFields.valueY = valueField;
    columnSeries.dataFields.categoryX = categoryField;
    columnSeries.yAxis = valueAxes;

    // set columns styles
    columnSeries.columns.template.tooltipText = this.tooltipText;
    columnSeries.columns.template.strokeOpacity = 0; // remove column border
    columnSeries.tooltip.label.textAlign = 'middle';

    // set column cursor styles
    columnSeries.columns.template.cursorOverStyle = cursorPointer
      ? am4core.MouseCursorStyle.pointer
      : am4core.MouseCursorStyle.default;

    // make each column with different color
    if (changeColumnColors) {
      columnSeries.columns.template.adapter.add('fill', function (fill, target) {
        return chart.colors.getIndex(target.dataItem.index);
      });
    }

    return columnSeries;
  }

  /**
   * Method to generate a line chart
   * @param chart
   * @param valueAxes (the y-axe previously generated)
   * @param serieTitle
   * @param valueField (name of the data field to be used for getting the value)
   * @param categoryField (name of the data field to be used for x-axe category > so value can be matched with category)
   */
  generateLineSeries(
    chart: am4charts.XYChart,
    valueAxes: am4charts.ValueAxis,
    serieTitle: string,
    valueField: string,
    categoryField: string
  ) {
    const lineSeries = chart.series.push(new am4charts.LineSeries());
    lineSeries.name = serieTitle;
    lineSeries.dataFields.valueY = valueField;
    lineSeries.dataFields.categoryX = categoryField;
    lineSeries.yAxis = valueAxes;

    // set line styles
    lineSeries.stroke = am4core.color('#fdd400');
    lineSeries.strokeWidth = 3;
    lineSeries.propertyFields.strokeDasharray = 'lineDash';
    lineSeries.tooltip.label.textAlign = 'middle';

    // set line bullet styles
    const bullet = lineSeries.bullets.push(new am4charts.Bullet());
    bullet.fill = am4core.color('#fdd400');
    bullet.tooltipText = this.tooltipText;

    // set line circle styles
    const circle = bullet.createChild(am4core.Circle);
    circle.radius = 4;
    circle.fill = am4core.color('#fff');
    circle.strokeWidth = 3;
  }

  generatePieChart(
    chart: am4charts.PieChart,
    valueField: string,
    categoryField: string,
    sliceBorder: boolean,
    labelsDisabled: boolean,
    legendPosition: am4charts.LegendPosition,
    absoluteNumbers = false
  ) {
    // Add and configure Series
    const pieSeries = chart.series.push(new am4charts.PieSeries());
    pieSeries.dataFields.value = valueField;
    pieSeries.dataFields.category = categoryField;

    // Make a hole in our Pie chart the size of 30% the radius
    // PieChart with a hole in the middle >> so like donut instead of pie
    chart.innerRadius = am4core.percent(30);

    // Put a thick white border around each Slice
    if (sliceBorder) {
      pieSeries.slices.template.stroke = am4core.color('#fff');
      pieSeries.slices.template.strokeWidth = 2;
      pieSeries.slices.template.strokeOpacity = 1;
    }

    // Change the cursor on hover to make it apparent the object can be interacted with
    pieSeries.slices.template.cursorOverStyle = [
      {
        property: 'cursor',
        value: 'pointer',
      },
    ];

    // Set label styles (text attached to each slice)
    pieSeries.labels.template.disabled = labelsDisabled;
    pieSeries.alignLabels = true;
    pieSeries.labels.template.bent = true;
    pieSeries.labels.template.radius = 3;
    pieSeries.labels.template.padding(0, 0, 0, 0);

    // use absolute numbers instead of percentage >> change piechart values
    if (absoluteNumbers) {
      pieSeries.labels.template.text = '{category}: {value.value}';
      pieSeries.slices.template.tooltipText = '{category}: {value.value}';
    }

    // Create hover state
    const hoverState = pieSeries.slices.template.states.getKey('hover');

    // Adds a shadow to hover state (more cool effect)
    const hoverShadow = hoverState.filters.push(new am4core.DropShadowFilter());
    hoverShadow.opacity = 0.7;
    hoverShadow.blur = 5;

    // On hover remove >> element roles back to origin state
    const shadow = pieSeries.slices.template.filters.push(new am4core.DropShadowFilter());
    shadow.opacity = 0;

    // Add a legend (overview of data with text,by default place down the chart horizontally)
    chart.legend = new am4charts.Legend();
    chart.legend.position = legendPosition;
    chart.legend.fontSize = 14;

    // use absolute numbers instead of percentage >> change chart legend values
    if (absoluteNumbers) {
      chart.legend.valueLabels.template.text = '{value.value}';
    }
  }

  generateTreeMap(
    chart: am4charts.TreeMap,
    valueField: string,
    nameField: string,
    visibleInitialLevels: number,
    isDrillDown: boolean,
    homeText: string,
    drillDownLevels: number
  ) {
    // set treemap number of levels visible at the beginning
    chart.maxLevels = visibleInitialLevels;

    if (isDrillDown) {
      // set treemap drill-down navigation (enable zoom and add navigation bar)
      chart.navigationBar = new am4charts.NavigationBar();
      chart.zoomable = true;
    }

    // define data fields (name and value)
    chart.dataFields.value = valueField;
    chart.dataFields.name = nameField;

    if (isDrillDown) {
      // define if treemap has children and based navogation title to use
      chart.dataFields.children = 'children';
      chart.homeText = homeText;
    }

    // make colors pallete more different from 1 color to another
    chart.colors.step = 3;

    // iterate over drilldown levels to set style
    for (let index = 0; index <= drillDownLevels; index++) {
      const seriesTemplate = chart.seriesTemplates.create(index.toString());
      const columnTemplate = seriesTemplate.columns.template;

      // set lines between columns and make cornes rounded
      columnTemplate.column.cornerRadius(10, 10, 10, 10);
      columnTemplate.strokeWidth = 5;

      // create hover efect
      const hoverState = columnTemplate.states.create('hover');
      hoverState.adapter.add('fill', function (fill, target) {
        if (fill instanceof am4core.Color) {
          return am4core.color(am4core.colors.brighten(fill.rgb, -0.2));
        }
        return fill;
      });

      // set if level is clickable
      if (index < drillDownLevels) {
        seriesTemplate.clickable = true;
      } else {
        seriesTemplate.clickable = false;
      }

      // add label to box (right in the middle)
      const bullet = seriesTemplate.bullets.push(new am4charts.LabelBullet());
      bullet.locationX = 0.5;
      bullet.locationY = 0.5;
      bullet.label.text = '{name}';
      bullet.label.fill = am4core.color('#ffffff');
      bullet.label.fontSize = index < drillDownLevels ? 18 : 12;
    }
  }

  generateFlagsBulletForColumnChart(marketplacesObjByName: any): am4core.Image {
    const image = new am4core.Image();
    image.horizontalCenter = 'middle';
    image.width = 20;
    image.height = 20;
    image.verticalCenter = 'middle';
    image.adapter.add('href', (href, target) => {
      const marketplace_name = target.dataItem['category'];
      if (marketplace_name) {
        return (
          'https://storage.googleapis.com/dendary-app-web/flags/' +
          marketplacesObjByName[marketplace_name].country_code.toLowerCase() +
          '.svg'
        );
      }
      return href;
    });

    return image;
  }

  generateImageForTreeChart(columnTemplate: am4charts.Column, marketplacesObjByName: any): am4core.Image {
    const image = columnTemplate.createChild(am4core.Image);
    image.opacity = 0.75;
    image.align = 'center';
    image.valign = 'middle';
    image.width = am4core.percent(60);
    image.height = am4core.percent(60);

    // add adapter for href to load correct image
    image.adapter.add('href', function (href, target) {
      const dataItem = target.parent.dataItem;
      if (dataItem) {
        const country_code = marketplacesObjByName[dataItem['treeMapDataItem']['name']].country_code.toLowerCase();
        return 'https://storage.googleapis.com/dendary-app-web/flags/' + country_code + '.svg';
      }
    });

    return image;
  }

  /**
   * Method  to set Y-axe title styles
   * @param axe
   * @param titleStyles
   */
  private setYAxeTitleStyles(axe: am4charts.ValueAxis, titleStyles: YAxeStyles) {
    if (titleStyles.fontWeight) {
      axe.title.fontWeight = titleStyles.fontWeight;
    }

    if (titleStyles.marginLeft) {
      axe.title.marginLeft = titleStyles.marginLeft;
    }

    if (titleStyles.marginRight) {
      axe.title.marginRight = titleStyles.marginRight;
    }
  }
}
