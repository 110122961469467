export const environment = {
  env: 'pro',
  domain: 'app.dendary.com',
  urls: {
    imagesBasePath: 'https://api.dendary.com',
    auth: 'https://api.dendary.com/v1/auth',
    sellerAccount: 'https://api.dendary.com/v1/seller_accounts',
    marketplace: 'https://api.dendary.com/v1/marketplaces',
    users: 'https://api.dendary.com/v1/users',
    products: 'https://api.dendary.com/v1/products'
  }
};
