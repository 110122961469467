import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from './../../../environments/environment';
import { map, retry, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { SellerAccount } from '../../shared/interfaces/seller-account.interface';
import { ErrorHandlerService } from './error-handler.service';

const status204 = 204;

@Injectable({
  providedIn: 'root',
})
export class SellerAccountService {
  sellerAccountUrl = environment.urls.sellerAccount;
  retries = 1;

  constructor(private readonly http: HttpClient) {}

  /**
   * Returns the list of seller accounts the user has privilages
   * to accesss
   */
  sellerAccounts(): Observable<SellerAccount[]> {
    const endpoint = this.sellerAccountUrl;

    return this.http.get<any>(endpoint).pipe(
      map(res => res),
      retry(this.retries),
      catchError(ErrorHandlerService.handleError)
    );
  }

  /**
   * Returns the sellerAccount selected
   */
  sellerAccount(sellerAccountId: number): Observable<SellerAccount> {
    const endpoint = `${this.sellerAccountUrl}/${sellerAccountId}`;

    return this.http.get<any>(endpoint).pipe(
      map(res => res.seller),
      retry(this.retries),
      catchError(ErrorHandlerService.handleError)
    );
  }

  /**
   * Creates a new sellerAccount (new sellerAccount belongs to user's company)
   * or updated its values
   * @param sellerAccountId
   * @param data
   */
  createSellerAccount(sellerAccountId: number, data: SellerAccount): Observable<SellerAccount | boolean> {
    const endpoint = sellerAccountId ? `${this.sellerAccountUrl}/${sellerAccountId}` : this.sellerAccountUrl;

    if (sellerAccountId) {
      return this.http.patch<any>(endpoint, data).pipe(
        map(res => true),
        catchError(ErrorHandlerService.handleError)
      );
    } else {
      return this.http.post<any>(endpoint, data).pipe(
        map(res => res.seller),
        catchError(ErrorHandlerService.handleError)
      );
    }
  }

  /**
   * Creates a new sellerAccount (new sellerAccount belongs to user's company)
   * @param data
   */
  saveSellerAccountAnalytics(sellerAccountId: number, data: FormData): Observable<boolean> {
    const endpoint = `${this.sellerAccountUrl}/${sellerAccountId}/add-analytics`;

    const headers = new HttpHeaders({
      'ngsw-bypass': '',
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
    });

    return this.http
      .post<any>(endpoint, data, { headers })
      .pipe(
        map(res => {
          return true;
        }),
        catchError(ErrorHandlerService.handleError)
      );
  }

  /**
   * Method to delete a Seller Account
   * @param sellerAccountId
   */
  deleteSellerAccount(sellerAccountId: number): Observable<boolean> {
    const endpoint = `${this.sellerAccountUrl}/${sellerAccountId}`;

    return this.http.delete<any>(endpoint).pipe(
      map(res => {
        return true;
      }),
      catchError(ErrorHandlerService.handleError)
    );
  }

  /**
   * Method for getting/refreshing seller account products
   * @param sellerAccountId
   */
  feedProducts(sellerAccountId: number): Observable<boolean> {
    const endpoint = `${this.sellerAccountUrl}/${sellerAccountId}/feed-products`;

    return this.http.post<any>(endpoint, {}).pipe(
      map(res => {
        return true;
      }),
      catchError(ErrorHandlerService.handleError)
    );
  }

  mainDataKpis(sellerAccountId: number): Observable<any> {
    const endpoint = `${this.sellerAccountUrl}/${sellerAccountId}/kpi/main_data`;

    return this.http.get<any>(endpoint).pipe(
      map(res => res.main_data),
      retry(this.retries),
      catchError(ErrorHandlerService.handleError)
    );
  }

  salesUnitsKpis(sellerAccountId: number): Observable<any> {
    const endpoint = `${this.sellerAccountUrl}/${sellerAccountId}/kpi/sales_units`;

    return this.http.get<any>(endpoint).pipe(
      map(res => res),
      retry(this.retries),
      catchError(ErrorHandlerService.handleError)
    );
  }

  marketsKpis(sellerAccountId: number): Observable<any> {
    const endpoint = `${this.sellerAccountUrl}/${sellerAccountId}/kpi/marketplaces`;

    return this.http.get<any>(endpoint).pipe(
      map(res => res),
      retry(this.retries),
      catchError(ErrorHandlerService.handleError)
    );
  }

  overviewKpis(): Observable<any> {
    const endpoint = `${this.sellerAccountUrl}/kpi/overview`;

    return this.http.get<any>(endpoint).pipe(
      map(res => res.kpi_overview),
      retry(this.retries),
      catchError(ErrorHandlerService.handleError)
    );
  }

  bestSellingProductsKpis(sellerAccountId: number, period: string): Observable<any> {
    const endpoint = `${this.sellerAccountUrl}/${sellerAccountId}/kpi/best-selling-products?period=${period}`;

    return this.http.get<any>(endpoint).pipe(
      map(res => res),
      retry(this.retries),
      catchError(ErrorHandlerService.handleError)
    );
  }
}
