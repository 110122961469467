import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from '../../../environments/environment';
import { StorageService } from './storage.service';

import { Subject, Observable } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';

import * as jwt_decode from 'jwt-decode';
import * as moment from 'moment';
import { Rol, User } from '../../shared/interfaces/user.interface';
import { UtilsService } from './utils.service';
import { ErrorHandlerService } from './error-handler.service';
import { Router } from '@angular/router';
import { AppConfig } from '../../configs/app.config';

const oneSec = 1000;

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  retries = 1;

  /** auth service basepath */
  authUrl = environment.urls.auth;

  /** to notify login event */
  onLogin: Subject<void>;

  constructor(private readonly http: HttpClient, private readonly router: Router) {
    this.onLogin = new Subject();
  }

  // ------------------------------------------------------------------------------------------------
  // Auth Endpoints ---------------------------------------------------------------------------------

  logIn(loginData): Observable<boolean> {
    return this.http.post(`${this.authUrl}/login`, loginData).pipe(
      map((response: any) => {
        // save user session data
        const user = {
          username: response.username,
          name: response.name,
          surname: response.surname,
          email: response.email,
          lang: response.lang,
          admin: response.admin,
          roles: response.roles,
        } as User;
        StorageService.saveUserSession(user, response.token, loginData.rememberMe);
        this.onLogin.next();
        return true;
      }),
      retry(this.retries),
      catchError(ErrorHandlerService.handleError)
    );
  }

  logOut(): void {
    StorageService.removeUserSession();
  }

  // ------------------------------------------------------------------------------------------------
  // User Helpers -----------------------------------------------------------------------------------

  isLoggedIn(): boolean {
    let currentUser = this.getUser();
    const isTokenExpired = this.isTokenExpired();
    if (isTokenExpired) {
      StorageService.removeUserSession();
      currentUser = null;
    }
    return currentUser !== null && !isTokenExpired;
  }

  getUser(): User {
    try {
      const localUser: User = StorageService.loadLocalCurrentUser();
      return localUser !== null ? localUser : StorageService.loadSessionCurrentUser();
    } catch (error) {
      return null;
    }
  }

  getUserToken(): string {
    try {
      const localToken = StorageService.loadLocalString('meta-token');
      return localToken !== null ? localToken : StorageService.loadSessionString('meta-token');
    } catch (error) {
      return null;
    }
  }

  userName(): string {
    const currentUser: User = this.getUser();
    return currentUser && currentUser.name ? currentUser.name : undefined;
  }

  userLang(): string {
    const currentUser: User = this.getUser();
    return currentUser !== null ? currentUser.lang : null;
  }

  // static userAvatar(): string {
  //   const currentUser: User = this.getUser();
  //   return (currentUser && currentUser.avatar) ? currentUser.avatar : undefined;
  // }

  userRedirect() {
    const user = this.getUser();

    if (user.admin || (user.roles['account_manager'] && user.roles['account_manager'].length)) {
      return this.router.navigate(['/dashboard']);
    } else {
      const sellerAccount = user.roles['seller'][0] as Rol;

      this.router.navigate([
        AppConfig.routes.sellerAccounts.basePath,
        sellerAccount.id,
        UtilsService.urelizeString(sellerAccount.name),
      ]);
    }
  }

  // ------------------------------------------------------------------------------------------------
  // Token Helpers ----------------------------------------------------------------------------------

  isTokenExpired(providedToken?): any {
    const token = providedToken || this.getUserToken();
    const tokenDecoded = this.decodeToken(token);

    if (tokenDecoded) {
      const expiresAt = moment(tokenDecoded.exp * oneSec);
      return moment().isAfter(expiresAt, 'day');
    } else {
      return true;
    }
  }

  decodeToken(token): any {
    return token ? jwt_decode(token) : undefined;
  }
}
