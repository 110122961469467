import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppConfig } from './configs/app.config';
import { AuthGuard } from './core/guards/auth.guard';
import { RoleGuard } from './core/guards/role.guard';
import { DashboardComponent } from './pages/dashboard/dashboard.component';

const routes: Routes = [
  // ------------------------------------------------------------------------
  // main routes (loadded within app.module at startup, not lazy-loading)
  // Private Home = Dashboard
  // ------------------------------------------------------------------------
  {
    path: AppConfig.routes.main.dashboard,
    component: DashboardComponent,
    canActivate: [AuthGuard, RoleGuard],
    data: {
      roles: [AppConfig.roles.admin, AppConfig.roles.accountManager],
    },
  },

  // ------------------------------------------------------------------------
  // auth routes (loadded within app.module at startup, with lazy-loading)
  // ------------------------------------------------------------------------
  {
    path: AppConfig.routes.auth.basePath,
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
  },

  // ------------------------------------------------------------------------
  // sellerAccounts routes (loadded within app.module at startup, with lazy-loading)
  // ------------------------------------------------------------------------
  {
    path: AppConfig.routes.sellerAccounts.basePath,
    loadChildren: () => import('./modules/seller-accounts/seller-accounts.module').then(m => m.SellerAccountsModule),
    canLoad: [AuthGuard],
  },

  // ------------------------------------------------------------------------
  // sellerAccounts routes (loadded within app.module at startup, with lazy-loading)
  // ------------------------------------------------------------------------
  {
    path: AppConfig.routes.users.basePath,
    loadChildren: () => import('./modules/users/users.module').then(m => m.UsersModule),
    canLoad: [AuthGuard, RoleGuard],
    data: {
      roles: [AppConfig.roles.admin],
    },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
