import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';
import { StorageService } from './storage.service';
import { Subject, Observable } from 'rxjs';

const initialYear = 1980;

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  /** Manage global lang settings for the app */
  translateSubject = new Subject<string>();

  /** Manage global spinner display */
  spinnerAppSubject = new Subject<boolean>();

  constructor() {}

  checkSpinner(): Observable<boolean> {
    return this.spinnerAppSubject.asObservable();
  }

  showSpinner() {
    this.spinnerAppSubject.next(true);
  }

  hideSpinner() {
    this.spinnerAppSubject.next(false);
  }
}
