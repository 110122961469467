import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { AuthService } from '../services/auth.service';
import { Observable, throwError as observableThrowError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly router: Router, private readonly authService: AuthService) {}

  // interceptor to include user token
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const headers = this.setHeader(request);
    const authReq = request.clone({ headers });

    return next.handle(authReq).pipe(
      catchError(err => {
        return observableThrowError(err);
      })
    );
  }

  /**
   * Set request headers. All request that are not GET are set to Content-Type: application/json
   * All but those that include a file(s) >> FormData
   * @param request
   */
  private setHeader(request) {
    let headers = request.headers;
    if (this.authService.isLoggedIn()) {
      headers = headers.set('Authorization', 'Bearer ' + this.authService.getUserToken());
    }
    if (request.method !== 'GET' && !(request.body instanceof FormData)) {
      headers = headers.set('Content-Type', 'application/json');
    }
    return headers;
  }
}
